<!-- src/components/Banner.vue -->
<template>
  <div v-if="visible" class="banner">
    <span
      >🚀 We're building a new lightweight OpenTelemetry collector!
      <a href="https://github.com/streamfold/rotel" target="_blank"
        >Check out Rotel on GitHub →</a
      ></span
    >
    <button class="close-btn" @click="visible = false">&times;</button>
  </div>
</template>

<script setup>
import { ref } from "vue"

const visible = ref(true)
</script>

<style scoped>
.banner {
  background-color: #e0f2fe;
  color: #0369a1;
  text-align: center;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.banner a {
  color: #0369a1;
  text-decoration: underline;
}

.close-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #0369a1;
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
}
</style>
